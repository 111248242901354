import { type CostOfWorks } from '@paid-ui/types';
import { type ContractPrice } from '@paid-ui/types/contracts';

import { initialState } from '../initialState';
import { contractManager } from '../model';

export const saveContractPrice = (price?: ContractPrice) => {
  contractManager.price.initial = price?.initial ?? 0;
  contractManager.price.current = price?.current ?? 0;
  contractManager.price.paid.percentage = price?.paid.percentage ?? 0;
  contractManager.price.paid.amount = price?.paid.amount ?? 0;
  contractManager.price.remaining.percentage = price?.remaining.percentage ?? 0;
  contractManager.price.remaining.amount = price?.remaining.amount ?? 0;
};

type CostOfWorksPayload = {
  initial: CostOfWorks;
  current: CostOfWorks;
};

export const saveCostOfWorks = (costOfWorks?: CostOfWorksPayload) => {
  contractManager.costOfWorks = costOfWorks?.current ?? initialState.costOfWorks;
  contractManager.initialCostOfWorks = costOfWorks?.initial ?? initialState.initialCostOfWorks;
};
